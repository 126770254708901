import React from 'react';
import { Col, Row, message } from 'antd';
import Button from '../../UI/buttons/Button';
import {
  inviteAllRecruitersToJob,
  sendInviteToRecruiters,
} from '../../api/recruiter';
import { useHistory } from 'react-router-dom';
import { JOBS } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import useHirePortTheme from '../../hooks/useHirePortTheme';

interface RecruiterManagementBoardFooterProps {
  numOfSelectedRecrutiers: number;
  highThreshold: number;
  companyName: string | undefined;
  allRows: string[];
  selectedRows: string[];
  recruitersSelectedInThisSession: string[];
  selectedJob: string | null;
  fromJobCreation: boolean;
  allSelected: boolean;
  allSelectedInThisSession: boolean;
  onConfirm?: (publish: boolean) => Promise<void>;
  onComplete: () => void;
}

export default function RecruiterManagementBoardFooter({
  numOfSelectedRecrutiers,
  highThreshold,
  companyName,
  allRows,
  selectedRows,
  recruitersSelectedInThisSession,
  selectedJob,
  fromJobCreation,
  onComplete,
  allSelected,
  allSelectedInThisSession,
  onConfirm,
}: RecruiterManagementBoardFooterProps) {
  const [t] = useTranslation();
  const history = useHistory();
  const theme = useHirePortTheme();

  const calculateAlertMsg = (
    numOfSelectedRecrutiers: number,
    highThreshold: number,
  ): string => {
    // let messagePrefix = numOfSelectedRecrutiers < highThreshold ? 'Only ' : '';
    return `${numOfSelectedRecrutiers} recruiters selected`;
  };

  const handleAddRecruitersToJob = (dontShowSuccessMessage?: boolean) => {
    if (allSelected) {
      handleAssignAllRecruiters(true, dontShowSuccessMessage);
      return;
    }

    if (!companyName || !selectedJob) return;

    sendInviteToRecruiters({
      company_name: companyName,
      selected_recruiters: recruitersSelectedInThisSession,
      job_id: selectedJob,
      loaded_recruiters: allSelectedInThisSession ? [] : allRows,
    })
      .then((_) => {
        if (!dontShowSuccessMessage)
          message.success('Talent suppliers assigned!');
        if (fromJobCreation) {
          history.push(JOBS + '/');
        }
        onComplete();
      })
      .catch((_) =>
        message.error('Ooops something went wrong, please try again later'),
      );
  };

  const handleAssignAllRecruiters = (
    selectAll?: boolean,
    dontShowSuccessMessage?: boolean,
  ) => {
    if (!companyName || !selectedJob) return;

    inviteAllRecruitersToJob({
      company_name: companyName,
      job_id: selectedJob,
      unselected_recruiters: selectAll
        ? []
        : allRows.filter((x) => !selectedRows.includes(x)),
    })
      .then((_) => {
        if (!dontShowSuccessMessage)
          message.success('Talent suppliers assigned!');
        if (fromJobCreation) {
          history.push(JOBS + '/');
        }
        onComplete();
      })
      .catch((_) =>
        message.error('Ooops something went wrong, please try again later'),
      );
  };

  let alertMsg = calculateAlertMsg(numOfSelectedRecrutiers, highThreshold);
  // let alertDescription = `Recomended number of recruiters is ${recomendedNumberOfRecrutiers}`;
  // let loaderPercentage =
  //   (numOfSelectedRecrutiers / recomendedNumberOfRecrutiers) * 100;

  return (
    <Row
      gutter={10}
      justify="space-between"
      align="middle"
      style={{
        marginTop: '0.5em',
        backgroundColor: theme.lozengeColor,
        borderRadius: theme.radius,
        padding: '0.5em',
      }}
    >
      <Col span={11}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontWeight: 600, fontSize: '14px' }}>{alertMsg}</div>
          {/* <div style={{ fontSize: '12px' }}>{alertDescription}</div> */}
          {/* <Progress
            trailColor="#fff"
            strokeLinecap="square"
            size="small"
            strokeColor={alertType}
            percent={loaderPercentage}
            showInfo={false}
          /> */}
        </div>
      </Col>
      <Col span="auto">
        {onConfirm ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <Button
              style={{ height: '50px', margin: '10px 10px 10px 40px' }}
              onClick={async () => {
                await handleAddRecruitersToJob(true);
                await onConfirm(false);
              }}
            >
              {t('SAVE_AND_PREVIEW_DRAFT_TITLE')}
            </Button>
            <Button
              type="primary"
              style={{ height: '50px', margin: '10px 0 10px 0' }}
              onClick={async () => {
                await handleAddRecruitersToJob(true);
                await onConfirm(true);
              }}
            >
              {t('PUBLISH_JOB_AND_INVITE')}
            </Button>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              size="middle"
              style={{
                height: '40px',
                color: '#627d95',
                borderColor: '#627d95',
                marginRight: '40px',
              }}
              onClick={() => handleAssignAllRecruiters(true)}
            >
              {t('RECRUITER_MODAL_CONTINUE_WITH_ALL')}
            </Button>
            <Button
              size="middle"
              type="primary"
              style={{ height: '40px' }}
              onClick={() => handleAddRecruitersToJob()}
            >
              {t('RECRUITER_MODAL_CONFIRM_SELECTION')}
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
}
