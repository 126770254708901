import RecruiterManagementBoard from '../../../components/recruiter/RecruiterManagementBoard';
import { useEffect, useState } from 'react';
import { ATS_SETTINGS, JOBS } from '../../../constants/routes';
import AntButton from '../../../UI/buttons/Button';
import styled from 'styled-components';
import { ReactComponent as EyeIcon } from '../../../icons/eye.svg';
import { ReactComponent as ConnectionIcon } from '../../../icons/department.svg';
import { Row } from 'antd';
import { useHistoryWithUrlBase } from '../../../hooks/useHirePortContext';
import useAtsStatus from './hooks/useAtsStatus';
import { useLocation } from 'react-router-dom';
import { postMessage } from '../../../components/helper/browserPlugins';
import { forceSync } from '../../../api/integrations';
import { publishJob } from '../../../api/jobs';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

const PageLayout = styled.div`
  padding: 2rem;
`;

const Button = styled(AntButton)`
  box-sizing: border-box;
  height: 40px;
  margin-bottom: 20px;
  margin-right: 10px;
  &:disabled,
  &.disabled {
    height: 40px;
    cursor: not-allowed;
    background: #fff;
    color: #aebeca;
    border-color: #aebeca;
    &:hover {
      background: #dde7ee;
      color: #ffffff;
      border-color: #dde7ee;
      & path {
        transition: all 0.3s;
        stroke: #fff;
      }
    }
    & path {
      stroke: #aebeca;
    }
  }
`;

const ChromiumAssignRecruitersPage = () => {
  const history = useHistoryWithUrlBase();
  const location = useLocation();
  const { status, connectedState, localJob, atsJob, refreshStatus } = useAtsStatus();
  const [syncRequested, setSyncRequested] = useState(false);
  const { pathname, search } = location;
  const [t] = useTranslation();

  useEffect(() => {
    if (status && !connectedState)
      history.push(`${ATS_SETTINGS}/?redirect=${encodeURIComponent(`${pathname}${search}`)}`);

    if (!syncRequested && connectedState && !atsJob) {
      setSyncRequested(true);
      forceSync();
    }

    if (syncRequested) {
      const handle = setInterval(async () => {
        refreshStatus();
      }, 30000);
      return () => {
        clearInterval(handle);
      }
    }
  }, [history, connectedState, pathname, search, status, atsJob, refreshStatus, syncRequested, setSyncRequested]);

  return (
    <PageLayout>
      {!connectedState && <h2>{t('Loading job connection...')}</h2>}
      {connectedState && !atsJob && <>
        <h2>{t('Bear with us...')}</h2>
        <p>{t("We couldn't find your ATS job straight away. If you've only just created it, please wait a few minutes as it can take some time to appear in our systems.")}</p>
        <p>{t("Just sit back and relax - this page will automatically update once we've found your job.")}</p>
        <img alt="In progress" style={{ width: 100, height: 100 }} src="/images/hireport-waiting2.gif" />
      </>}
      {connectedState && atsJob && !localJob && <h2>{t('Creating linked job...')}</h2>}
      {connectedState && atsJob && localJob && <>
        <Row>
          <Button size="middle" onClick={() => { history.push(`${JOBS}/${localJob.id}`) }}><EyeIcon />{t('View: Supplier Job')}</Button>
          <Button size="middle" onClick={() => { history.push(`${ATS_SETTINGS}${JOBS}/${localJob.id}`) }}><ConnectionIcon />{t('Supplier Job Stages')}</Button>
        </Row>
        <RecruiterManagementBoard
          fromJobCreation={false}
          selectedJob={localJob.id}
          onComplete={async () => {
            try {
              await publishJob(localJob.id);
            } catch (error) {
              message.error(t('UPDATE_ERROR'));
            } finally {
              message.success(t('JOB_UPDATED'));
              postMessage({ event: 'UIActionComplete' });
            }
          }}
        />
      </>}
    </PageLayout>
  );
};

export default ChromiumAssignRecruitersPage;
