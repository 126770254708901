import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import { hotjar } from 'react-hotjar';
import PublicRoute from './PublicRoute';
import {
  CALENDAR,
  CANDIDATES,
  COMPANIES,
  DASHBOARD,
  HOME,
  INVITE,
  JOBS,
  LOGIN,
  MESSAGES,
  ONBOARD,
  RECRUITERS,
  RESET_PASSWORD,
  LINKEDIN_REDIRECT,
  GOOGLE_REDIRECT_HANDLER,
  PROFILE_SETTINGS,
  CANDIDATES_SPECIFICATION_SETTINGS,
  RECRUITER_MANAGEMENT,
  PAYMENT_INFO_SETTINGS,
  NOTIFICATIONS_SETTINGS,
  NOTIFICATIONS,
  GUEST,
  PRIVACY_POLICY,
  RATE,
  PAYMENTS,
  REGISTER,
  MAGIC_LINK_INIT,
  MAGIC_LINK,
  RECRUITER,
  CLIENT,
  ATS_SETTINGS,
  ANALYTICS,
  MASTER_CONTRACTS,
  PARTNER_DASHBOARD,
  BROWSER_PLUGINS,
} from '../../constants/routes';
import Login from '../../pages/Login';
import GoogleRedirectHandler from '../../pages/GoogleRedirectHandler';
import PrivateRoute from './PrivateRoute';
import Dashboard from '../../pages/Dashboard';
import Home from '../../pages/Home';
import Jobs from '../../pages/Jobs';
import Job from '../../pages/Job';
import JobSteps from '../../pages/JobSteps';
import Candidates from '../../pages/Candidates';
import Recruiters from '../../pages/Recruiters';
import Clients from '../../pages/Clients';
import Messages from '../../pages/Messages';
import InvitationConfirm from '../../pages/InvitationConfirm';
import Onboard from '../../pages/Onboard';
import ResetPassword from '../../pages/ResetPassword';
import Calendar from '../../pages/Calendar';
import SingleJob from '../../pages/SingleJob';
import ProfileSettings from '../../pages/ProfileSettings';
import CandidatesSpecificationSettings from '../../pages/CandidatesSpecificationSettings';
import PaymentInfoSettings from '../../pages/PaymentInfoSettings';
import NotificationsSettings from '../../pages/NotificationsSettings';
import NotificationRoute from './NotificationRoute';
import GuestRoute from './GuestRoute';
import Guest from '../../pages/Guest';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import Rate from '../../pages/Rate';
import NotFound from '../../pages/NotFound';
import Payments from '../../pages/Payments';
import MagicLinkInit from '../../pages/MagicLinkInit';
import MagicLink from '../../pages/MagicLink';
import { useAppSelector } from '../../store/hooks';
import { CLIENT_ROLE, RECRUITER_ROLE } from '../../constants/roles';
import PublicJobCreate from '../../pages/PublicJobCreate';
import RecruiterPublicProfile from '../../pages/RecruiterPublicProfile';
import ClientPublicProfile from '../../pages/ClientPublicProfile';
import AtsSettingsPage from '../../pages/AtsSettings/AtsSettings';
import AtsJobSettings from '../../pages/AtsSettings/AtsJobSettings';
import RecruiterManagementPage from '../../pages/RecruiterManagement';
import Analytics from '../../pages/Analytics';
import PartnerDashboard from '../../pages/PartnerDashboard';
import { BrowserPluginRoutes } from '../../pages/browser-plugins';
import MasterContracts from '../../pages/MasterContracts/MasterContracts';
import EditMasterContract from '../../pages/MasterContracts/EditMasterContract';

const Routes = () => {
  const { user } = useAppSelector((state) => state.user);
  const isNotAdmin =
    user?.role === CLIENT_ROLE || user?.role === RECRUITER_ROLE;
  useEffect(() => {
    if (isNotAdmin) {
      if (process.env.REACT_APP_HOTJAR_ID) {
        hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_ID), 1);
      }
    } /*else{
      const scripts = document.querySelectorAll('script')
    const iframes = document.querySelectorAll('iframe')
    console.log(scripts)
      scripts.forEach(el => {
        if (el.src.includes('hotjar')){
      el.remove()
    }
      })
      iframes.forEach(el => {
        if (el.src.includes('hotjar')){
      el.remove()
    }
      })
    }*/
  }, [isNotAdmin]);
  return (
    <div>
      <Router>
        <Switch>
          <PublicRoute path={HOME} exact component={Home} />
          <PublicRoute
            path={RESET_PASSWORD + '/:uid/:token'}
            component={ResetPassword}
          />
          <PublicRoute
            path={INVITE + '/:uid/:token'}
            component={InvitationConfirm}
          />
          <PublicRoute path={LINKEDIN_REDIRECT} component={LinkedInPopUp} />
          <PublicRoute
            path={GOOGLE_REDIRECT_HANDLER}
            component={GoogleRedirectHandler}
          />
          <PublicRoute path={JOBS + '/new'} exact component={PublicJobCreate} />
          <PrivateRoute path={ONBOARD} component={Onboard} />
          <PrivateRoute path={DASHBOARD} exact component={Dashboard} />
          <PrivateRoute path={JOBS} exact component={Jobs} />
          <PrivateRoute
            path={RECRUITER + '/:recruiterId/'}
            exact
            component={RecruiterPublicProfile}
          />
          <PrivateRoute
            path={RECRUITER + '/:recruiterId/edit/:recruiterCompanyAgreementId'}
            exact
            component={RecruiterPublicProfile}
          />
          <PrivateRoute
            path={RECRUITER_MANAGEMENT}
            exact
            component={RecruiterManagementPage}
          />
          <PrivateRoute path={ANALYTICS} exact component={Analytics} />
          <PrivateRoute
            path={MASTER_CONTRACTS}
            exact
            component={MasterContracts}
          />
          <PrivateRoute
            path={MASTER_CONTRACTS + '/:id'}
            exact
            component={EditMasterContract}
          />
          <PrivateRoute
            path={PARTNER_DASHBOARD}
            exact
            component={PartnerDashboard}
          />
          <PrivateRoute
            path={CLIENT + '/:clientId/'}
            exact
            component={ClientPublicProfile}
          />
          <PrivateRoute path={JOBS + '/:id'} exact component={SingleJob} />
          <Route
            path={'/public' + JOBS + '/:id'}
            exact
            render={(props) => {
              return <SingleJob isPublic={true} />;
            }}
          />
          <PrivateRoute
            path={JOBS + '/:id/:candidate'}
            exact
            render={() => {
              return <SingleJob isPublic={false} />;
            }}
          />
          <PrivateRoute path={JOBS + '/edit/:id'} exact component={Job} />
          <PrivateRoute
            path={JOBS + '/edit/:id/:step'}
            exact
            component={JobSteps}
          />
          <PrivateRoute path={CANDIDATES} exact component={Candidates} />
          <PrivateRoute path={RECRUITERS} exact component={Recruiters} />
          <PrivateRoute path={COMPANIES} exact component={Clients} />
          <PrivateRoute path={MESSAGES} exact component={Messages} />
          <PrivateRoute
            path={MESSAGES + '/:contactId'}
            exact
            component={Messages}
          />
          <PrivateRoute
            path={MESSAGES + '/:contactId/:interviewId'}
            exact
            component={Messages}
          />
          <PrivateRoute path={CALENDAR} exact component={Calendar} />
          <PrivateRoute
            path={PROFILE_SETTINGS}
            exact
            component={ProfileSettings}
          />
          <PrivateRoute
            path={CANDIDATES_SPECIFICATION_SETTINGS}
            exact
            component={CandidatesSpecificationSettings}
          />
          <PrivateRoute
            path={PAYMENT_INFO_SETTINGS}
            exact
            component={PaymentInfoSettings}
          />
          <PrivateRoute path={ATS_SETTINGS} exact component={AtsSettingsPage} />
          <PrivateRoute
            path={`${ATS_SETTINGS}${JOBS}/:id`}
            exact
            component={AtsJobSettings}
          />
          <PrivateRoute path={PAYMENTS} exact component={Payments} />
          <PrivateRoute
            path={NOTIFICATIONS_SETTINGS}
            exact
            component={NotificationsSettings}
          />
          <PrivateRoute path={RATE + '/:interviewId'} exact component={Rate} />
          <NotificationRoute path={NOTIFICATIONS} exact component={LOGIN} />
          <Route path={PRIVACY_POLICY} exact component={PrivacyPolicy} />
          <GuestRoute path={GUEST} exact component={Guest} />
          <PublicRoute path={LOGIN} exact component={Login} />
          <PublicRoute path={REGISTER} exact component={Login} />
          <Route path={MAGIC_LINK_INIT} component={MagicLinkInit} />
          <Route path={MAGIC_LINK} exact component={MagicLink} />
          <Route path={BROWSER_PLUGINS}>
            <BrowserPluginRoutes noMatch={<PrivateRoute component={NotFound} />} />
          </Route>
          <PrivateRoute component={NotFound} />
        </Switch >
      </Router >
    </div >
  );
};

export default Routes;
